@import url("./styles/base.css");

@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    display: flex;
    height: 100vh;
    flex-direction: column;
    width: 100vw;
    overflow: hidden;
}

#root > * {
    padding: 1rem;
}

#root > main {
    flex: 1
}

.ant-card {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ant-card-body {
    flex: 1
}

/* .ant-spin-nested-loading,
.ant-spin-container,
.ant-table,
.ant-table-container,
.ant-table-content,
.ant-table-body {
  height: 100%;
  max-height: 100%;
}

.ant-table-body{
  overflow-y: auto;
}

.ant-spin-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 0;
} */

.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 2;
    margin: 0 0 5px 1px;
}

.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
    display: none;
}

.ant-picker {
    width: 100%;
}

button.ant-drawer-close {
    order: 2;
    margin-inline-end: 0 !important;
}

form#timeSheetTable span.ant-select-selection-item {
    text-align: center;
}

form#timeSheetTable .ant-input-outlined.ant-input-disabled,
form#timeSheetTable .ant-input-outlined[disabled] {
    text-align: center;
}

.form-item-margin .ant-form-item {
    margin: 0;
}

.ant-select.option-center {
    text-align: center;
}

.ant-form-item.item-center .ant-col.ant-form-item-label {
    text-align: center;

}

.tab-no-children .ant-tabs-top > .ant-tabs-nav,
.tab-no-children .ant-tabs-bottom > .ant-tabs-nav,
.tab-no-children .ant-tabs-top > div > .ant-tabs-nav,
.tab-no-children .ant-tabs-bottom > div > .ant-tabs-nav {
    /*margin: 0*/
}
